import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeploymentsService {
  private apiUrl = environment.apiUrl;
  private legacyUrl = environment.legacyUrl;

  constructor(public http: HttpClient, private toastr: ToastrService) { }

  getEnvironment() {
    return this.http.get(`${this.apiUrl}/`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getEnvironmentById(env : string){
    return this.http.get(`${this.apiUrl}/${env}`)  
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  integrateWithGitHub(){
    return this.http.get(`${this.legacyUrl}/auth/github`)  
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  createEnvironment(req: any) {
    return this.http.post(`${this.legacyUrl}/environments`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDeployments(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/deployments`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getDeploymentById(env: string, name: string){
    return this.http.get(`${this.apiUrl}/${env}/deployments/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  updateDeployment(env: string, name: string, req: any){
    return this.http.put(`${this.apiUrl}/${env}/deployments/${name}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  updateResources(env: string, name: string, req: any){
    return this.http.put(`${this.apiUrl}/${env}/deployments/${name}/resources`, req)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  updateEnvironmentVariables(env: string, name: string, req: any){
    return this.http.put(`${this.apiUrl}/${env}/deployments/${name}/secret&config`, req)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  restartDeployment(env:string, name:string, req:any){
    return this.http.post(`${this.apiUrl}/${env}/deployments/${name}/rollout`,req)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  deleteDeployments(env:string, name: string){
    return this.http.delete(`${this.apiUrl}/${env}/deployments/${name}`)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  getDeploymentLogs(env: string, name: string, req: any) {
    let params = new HttpParams()
      .set('previous_logs', req);
    return this.http.get(`${this.apiUrl}/${env}/deployments/${name}/logs`, { params })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getEndPoints(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/endpoints`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  getEndPointsById(env: string, id: string) {
    return this.http.get(`${this.apiUrl}/${env}/endpoints/${id}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  updateEndPointsById(env: string, id: string, req: any) {
    return this.http.put(`${this.apiUrl}/${env}/endpoints/${id}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  getServiceList(env: any) {
    return this.http.get(`${this.apiUrl}/${env}/service`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  createEndPoints(env: string, req: any) {
    return this.http.post(`${this.apiUrl}/${env}/endpoints`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteEndPoints(env:string, name: string){
    return this.http.delete(`${this.apiUrl}/${env}/endpoints/${name}`)
    .pipe(
      catchError(this.handleError.bind(this))
    );
  }

  createSecret(env: string, req: any) {
    return this.http.post(`${this.apiUrl}/${env}/secrets`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  updateSecret(env: string, name: string, req: any) {
    return this.http.put(`${this.apiUrl}/${env}/secrets/${name}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  createConfig(env: string, req: any) {
    return this.http.post(`${this.apiUrl}/${env}/configs`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  updateConfig(env: string, name: string, req: any) {
    return this.http.put(`${this.apiUrl}/${env}/configs/${name}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getSecretsList(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/secrets`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  getSecretByName(env: string, name: string) {
    return this.http.get(`${this.apiUrl}/${env}/secrets/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  
 deleteSecret(env: string, name: string){
  return this.http.delete(`${this.apiUrl}/${env}/secrets/${name}`)
    .pipe(
      catchError(this.handleError.bind(this))
    );
}

  getConfigList(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/configs`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }
  getConfigByName(env: string, name: string) {
    return this.http.get(`${this.apiUrl}/${env}/configs/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      )
  }
  deleteConfigs(env: string, name: string){
    return this.http.delete(`${this.apiUrl}/${env}/configs/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getToolsList(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/tools`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getAvailableToolsList() {
    return this.http.get(`${this.legacyUrl}/tools`)
      .pipe(
        catchError(this.handleError)
      );
  }
  getFormDetailsByTool(id: any) {
    return this.http.get(`${this.legacyUrl}/tools/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getToolsValuesToUpdate(env: string, name: string) {
    return this.http.get(`${this.apiUrl}/${env}/tools/${name}/values`)
      .pipe(
        catchError(this.handleError)
      );
  }

  createTools(env: string, req: any) {
    return this.http.post(`${this.apiUrl}/${env}/tools`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getToolDetailsById(env: string, id: any) {
    return this.http.get(`${this.apiUrl}/${env}/tools/${id}/resources`)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateTools(env: string, name: string, req: any) {
    return this.http.put(`${this.apiUrl}/${env}/tools/${name}`, req)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  deleteTools(env: string, name: string){
    return this.http.delete(`${this.apiUrl}/${env}/tools/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getUsageCount(env: string) {
    return this.http.get(`${this.apiUrl}/${env}/usage/monthly`)
      .pipe(
        catchError(this.handleError)
      );
  }
  getUsageByFilter(env: string, type: string) {
    return this.http.get(`${this.apiUrl}/${env}/usage/${type}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getResourceQuota(env: string,){
    return this.http.get(`${this.apiUrl}/${env}/quota/resources`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getServiceQuota(env: string,){
    return this.http.get(`${this.apiUrl}/${env}/objectcount`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getVolumeByName(env:string, name:string){
    return this.http.get(`${this.apiUrl}/${env}/volume/${name}`)
    .pipe(
      catchError(this.handleError)
    );
  }

  getVolumesList(env: string){
    return this.http.get(`${this.apiUrl}/${env}/volume`)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteVolumes(env: string, name: string){
    return this.http.delete(`${this.apiUrl}/${env}/volume/${name}`)
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  getCallback(auth_code : string) {
    let params = new HttpParams()
      .set('code', auth_code);
   return this.http.get(`${this.legacyUrl}/auth/github/callback`,{params})
   .pipe(
     catchError(this.handleError.bind(this))
   );
 }

 getUserProfile() {
 return this.http.get(`${this.legacyUrl}/auth/github/userprofile`)
 .pipe(
   catchError(this.handleError.bind(this))
 );
}

 getUserRepos() {
   return this.http.get(`${this.legacyUrl}/auth/github/repos`)
   .pipe(
     catchError(this.handleError.bind(this))
   );
 }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      this.toastr.error(error.error?.error)
    } else {
      // console.error(
      //   `Backend returned code ${error.status}, ` +
      //   `body was: ${JSON.stringify(error.error)}`);
      const errorMessage = error.error?.error || 'Please try again later';
      this.toastr.error(errorMessage, 'Error');
    }

    return throwError('Something bad happened; please try again later.');
  }
}
