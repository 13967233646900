import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private valueChangeSource = new Subject<string>();
  valueChange$ = this.valueChangeSource.asObservable();

  private envValueChangeSource = new Subject<string>();
  envValueChange$ = this.envValueChangeSource.asObservable();

  private isLoading = new BehaviorSubject<boolean>(false); 
  public isLoading$ = this.isLoading.asObservable();

  constructor(private cookieService: CookieService) { }

  emitValueChange(value: string) {
    this.valueChangeSource.next(value);
  }
  emitEnvValueChange(value: string) {
    this.envValueChangeSource.next(value);
  }

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  setCookie(name: string, value: string, expireDays: number): void {
    this.cookieService.set(name, value, expireDays);
  }

  getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  deleteCookie(name: string): void {
    this.cookieService.delete(name);
  }
}
