import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import {AuthGuard} from './services/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/routes').then((m) => m.routes)
      },
      {
        path: 'deployment',
        loadChildren: () => import('./deployments/routes').then((m) => m.routes)
      },
      {
        path: 'create-deployment',
        loadChildren: () => import('./deployments/setup-deployments/routes').then((m) => m.routes)
      },
      {
        path:'edit-deployment',
        loadChildren: () => import('./deployments/create-deployments/routes').then((m) => m.routes)
      },
      {
        path: 'deployment-logs',
        loadChildren: () => import('./deployments/deployment-logs/routes').then((m) => m.routes)
      },
      {
        path: 'endpoints',
        loadChildren: () => import('./endpoints/routes').then((m) => m.routes)
      },
      {
        path: 'create-endpoint',
        loadChildren: () => import('./endpoints/create-endpoints/routes').then((m) => m.routes)
      },
      {
        path: 'configs',
        loadChildren: () => import('./configurations/routes').then((m) => m.routes)
      },
      {
        path: 'create-config',
        loadChildren: () => import('./configurations/create-config/routes').then((m) => m.routes)
      },
      {
        path: 'secrets',
        loadChildren: () => import('./secrets/routes').then((m) => m.routes)
      },
      {
        path: 'create-secret',
        loadChildren: () => import('./secrets/create-secret/routes').then((m) => m.routes)
      },
      {
        path: 'tools',
        loadChildren: () => import('./tools/routes').then((m) => m.routes)
      },
      {
        path: 'create-tool',
        loadChildren: () => import('./tools/create-tool/routes').then((m) => m.routes)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/routes').then((m) => m.routes)
      },
      {
        path:'volumes',
        loadChildren: () => import('./volumes/routes').then((m)=>m.routes)
      }
    ]
  },
  {
    path: '404',
    loadComponent: () => import('./views/pages/page404/page404.component').then(m => m.Page404Component),
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    loadComponent: () => import('./views/pages/page500/page500.component').then(m => m.Page500Component),
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./views/pages/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Login Page'
    }
  },
  {
    path:'callback',
    loadComponent: () => import('./redirect/redirect.component').then(m => m.RedirectComponent),
  },
  {
    path: 'register',
    loadComponent: () => import('./views/pages/register/register.component').then(m => m.RegisterComponent),
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'environment',
    loadComponent: () => import('./environment/environment.component').then(m => m.EnvironmentComponent),
    data: {
      title: 'Environment Page'
    }
  },
   { path: '**', redirectTo: 'dashboard' }
];
